/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import "./header.scss";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../../api/request/Broker/broker.service";
import WelcomeEmployee from "../../Rebranding/organisms/Modal/WelcomeEmployee/WelcomeEmployee.component";
import HttpTrackingRepository from "../../../api/request/Tracking/tracking.service";
import { I18nContext } from "../../../contexts/i18n.context";
import { Link } from "react-router-dom";
import { mapRoutes } from "../../../Routes/routes";
import {
  ButtonDS,
  CustomModal,
  GlobalIcon,
  IconButton,
  useDragScroll,
} from "get-life-storybook-ts";
import posthog from "posthog-js";

export default function Header({ children }) {
  const {
    broker,
    brokerId,
    setBroker,
    setBrokerId,
    setShowNotification,
    setStatus,
    setStatusEmployee,
    setToken,
    status,
    rol,
    token,
  } = useContext(ContextGetlife);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const location = useLocation();
  const navigate = useNavigate();

  const brokerRepository = new HttpBrokerRepository();
  const trackingRepository = new HttpTrackingRepository();

  const actualUrl = location.pathname;

  const isActiveLink = (url) =>
    actualUrl.includes(url) ? "header-activeLi" : "header-noActiveLi ";

  const logout = (error) => {
    trackingRepository.add({
      eventType: "LOGOUT",
      eventDetails: [
        {
          token: token,
          brokerEmployeeId: brokerId,
          fromURL: actualUrl,
          method: error || "BUTTON",
        },
      ],
    });

    setBroker(undefined);
    setBrokerId("");
    setToken("");
    setStatus("");
  };

  const fetchFullStory = async (broker) => {
    posthog.identify(
      broker.brokerageId, // Required. Replace 'distinct_id' with your user's unique identifier
      { email: broker.email } // $set, optional
    );
  };

  const fetch = async () => {
    try {
      const broker = await brokerRepository.getBroker(token);

      setBroker(broker);
      setBrokerId(broker.id);
      setStatus(broker.status);
      setStatusEmployee(broker.brokerEmployeeStatus);

      await fetchFullStory(broker);
    } catch (e) {
      if (e.code !== 400) {
        if (e.code === 401) {
          logout("ERROR");
        } else if (e.code === 404) {
          navigate("/not-found");
        } else {
          setShowNotification({
            message: translate("errors.header.message"),
            status: true,
            type: "error",
            autoHideDuration: 10000,
            link: (
              <a
                style={{
                  color: "white",
                }}
                href="mailto:broker@life5.com"
              >
                {" "}
                product@life5.com
              </a>
            ),
          });
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetch();
    })();
  }, [actualUrl, status, token]);

  const refHelpModal = useRef();
  const [ref] = useDragScroll();

  return (
    <>
      <WelcomeEmployee />
      <div className="header">
        <div className="header-content">
          <div className="header-logos">
            {broker && broker.logoFilename ? (
              <img
                src={broker.logoFilename}
                alt="logo del broker"
                className="header-brokerLogo"
              />
            ) : broker ? (
              <p className={"header-brokerName BodyM"}>
                {broker.brokerageName}
              </p>
            ) : null}
            <div className="header-divider" />
            <img
              src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
              alt="GetLife"
              className="header-life5Logo"
            />
          </div>
          <nav className="header-navbar" ref={ref}>
            {status !== "questionnaire_pending" &&
              mapRoutes.allowedRoutes(rol).map(
                (route, index) =>
                  route.title && (
                    <Link
                      to={route.path}
                      className={`header-link ${isActiveLink(
                        route.path
                      )} BodyM`}
                      key={route.title}
                      draggable={false}
                    >
                      {translate(route.title)}
                    </Link>
                  )
              )}
            {broker?.complementaryUrl && (
              <div className="header-ctas">
                <ButtonDS
                  onClick={() => navigate("/complementary")}
                  label={translate("navbar.complementary")}
                  buttonType="tertiary"
                  size="32"
                  className="header-helpButton"
                />
              </div>
            )}
          </nav>
          <div className="header-ctas">
            <Link to="/company_info" className="BodyL header-infoLink">
              <GlobalIcon iconName={"SettingsIcon"} size="XS" />
              {translate("navbar.link.companyInfo")}
            </Link>
            <ButtonDS
              onClick={() => refHelpModal.current?.handleShowPortal()}
              label={translate("navbar.help")}
              leftIcon="MailForwardIcon"
              buttonType="secondary"
              size="40"
              className="header-helpButton"
            />
            <IconButton
              icon="LogoutIcon"
              size="40"
              onClick={() => {
                logout();
                navigate("/login");
              }}
            />
          </div>
        </div>
      </div>
      {children}
      <CustomModal
        ref={refHelpModal}
        ModalBody={
          <div>
            <p
              className="BodyM header-modalContent"
              dangerouslySetInnerHTML={{
                __html: translate("navbar.helpModal.content"),
              }}
            />
            <div className="header-modalCTA">
              <ButtonDS
                href={translate("navbar.helpModal.callPhone")}
                label={translate("navbar.helpModal.call")}
                leftIcon="PhoneCallIcon"
                ghost
                className="flex-1"
              />
              <ButtonDS
                href={translate("navbar.helpModal.scheduleUrl")}
                target="_blank"
                label={translate("navbar.helpModal.scheduleCall")}
                rightIcon="ArrowRightIcon"
                className="flex-1"
              />
            </div>
          </div>
        }
        ModalTitle={
          <div className="H4 header-modalTitle">
            <GlobalIcon iconName="HelpIcon" size="XS" color="currentColor" />
            {translate("navbar.helpModal.title")}
          </div>
        }
        fitContent
      />
    </>
  );
}
